import { createClient } from "@supabase/supabase-js";

// export const supabase = createClient(
//   process.env.VUE_APP_SUPABASE_URL as string,
//   process.env.VUE_APP_SUPABASE_KEY as string
// )

export const supabase = createClient(
  (process.env.VUE_APP_SUPABASE_URL =
    "https://ytywnkhxemgxipvwdoto.supabase.co"),
  (process.env.VUE_APP_SUPABASE_KEY =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inl0eXdua2h4ZW1neGlwdndkb3RvIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NDkyNTY0OTAsImV4cCI6MTk2NDgzMjQ5MH0.WdncbpuwDgsowYrjrYmEZ9ymKkKIPcgVT9ulOHIM2Pw")
);
